import React, { useContext, useEffect } from "react";
import { graphql, Link } from "gatsby";
import { withPreview } from "gatsby-source-prismic";
import { Row, Col } from "styled-bootstrap-grid";
import Helmet from "react-helmet";
import styled from "styled-components";

// Effects
import { Fade } from "react-awesome-reveal";

// Components
import { FlexContainer } from "../components/containers/flex-container";
import { AspectRatioImageContainer } from "../components/containers/aspect-ratio-image-container";
import { PageTitle } from "../components/text/page-title";
import { Spacer } from "../components/utils/spacer";

// Context
import { FooterColor } from "../components/context/footer-color";

// Styles
import { tertiary } from "../components/utils/colors";

const Page = styled.div``;

const ArticleContainer = styled.div`
	& .article-text {
		margin: 15px 0 30px 0;

		& p {
			margin: 0;
		}
	}
`;

const CareGuides = ({ data }) => {
	const [footerColor, setFooterColor] = useContext(FooterColor);

	useEffect(() => {
		setFooterColor(tertiary);
	}, []);

	const articles = data.allPrismicCareGuide.edges.map((content, index) => (
		<Col col={12} md={6} lg={4} key={`journal_article_${index}`}>
			<Link to={content.node.url}>
				<Fade cascade triggerOnce>
					<ArticleContainer>
						<AspectRatioImageContainer image={null} padding={`66.667`}>
							{content.node.data.thumbnail.fluid !== null && (
								<img
									srcSet={content.node.data.thumbnail.fluid.srcSetWebp}
									src={content.node.data.thumbnail.fluid.srcWebp}
									alt={content.node.data.thumbnail.alt}
									loading={`lazy`}
								/>
							)}
						</AspectRatioImageContainer>
						<div className="article-text large">
							<p>{content.node.data.title.text}</p>
						</div>
					</ArticleContainer>
				</Fade>
			</Link>
		</Col>
	));

	return (
		<>
			<Helmet
				title={`${data.prismicCareGuides.data.seo_title}`}
				meta={[
					{
						name: "og:title",
						content: `${data.prismicCareGuides.data.seo_title}`,
					},
					{
						name: "description",
						content: `${data.prismicCareGuides.data.seo_description}`,
					},
					{
						name: "og:description",
						content: `${data.prismicCareGuides.data.seo_description}`,
					},
					{
						name: "twitter:title",
						content: `${data.prismicCareGuides.data.seo_title}`,
					},
					{
						name: "twitter:description",
						content: `${data.prismicCareGuides.data.seo_description}`,
					},
				]}
			/>
			<Page>
				<PageTitle title={data.prismicCareGuides.data.title.html} />

				<FlexContainer
					largeDesktopPadding={`100px 135px 0 135px`}
					desktopPadding={`100px 60px 0 60px`}
					tabletPadding={`60px 35px 0 35px`}
					mobilePadding={`60px 15px`}
					cv={true}
				>
					<Row>{articles}</Row>
				</FlexContainer>
			</Page>
			<Spacer />
		</>
	);
};

export const query = graphql`
	{
		prismicCareGuides {
			data {
				title {
					html
				}
				seo_title
				seo_description
			}
		}
		allPrismicCareGuide(sort: { order: ASC, fields: data___title___text }) {
			edges {
				node {
					url
					data {
						title {
							text
						}
						thumbnail {
							alt
							dimensions {
								height
								width
							}
							fluid(imgixParams: { ar: "3:2", fit: "crop", crop: "entropy" }) {
								srcSetWebp
								srcWebp
								aspectRatio
							}
						}
					}
				}
			}
		}
	}
`;

export default withPreview(CareGuides);
